<template>
    <b-overlay :show="loading" rounded="sm">
        <!-- 1 -->
        <b-card no-body class="mb-4">
            <!-- Search Control -->
            <b-card-body class="p-3 d-flex justify-content-between">
                <b-form-group class="mb-2 w-lg-25">
                    <b-input-group>
                        <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                            @input="debouncedSearch"
                        ></b-form-input>

                        <b-input-group-append>
                            <b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <div class="d-flex align-items-center">
                    <b-button variant="primary" class="mr-2" to="/customers/new?type=business">New Company</b-button>
                </div>
            </b-card-body>
            <!-- End Section -->

            <!-- Data Table -->
            <b-table
                show-empty
                responsive
                no-local-sorting
                class="mb-0"
                head-variant="light"
                :items="companies"
                :fields="fields"
                hover
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                style="min-height: 300px"
            >
                <template #cell(id)="data"> #{{ data.item.id }} </template>
                <template #cell(origin)="data">{{ data.item.originDescription }}</template>
                <template #cell(isActive)="data">
                    <b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
                    <b-badge variant="danger" v-else>Archived</b-badge>
                </template>
                <template #cell(action)="data">
                    <b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
                        <b-dropdown-item @click="onRowClicked(data.item)">View detail</b-dropdown-item>
                        <b-dropdown-item @click="changeStatus(data.item)">
                            {{ data.item.isActive ? 'Deactivate' : 'Activate' }}
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <!-- End Section -->

            <!-- Pagination -->
            <div class="p-3 d-md-flex align-items-center">
                <b-form-group
                    label="Per page"
                    label-for="per-page-select"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="9"
                    label-size="sm"
                    label-class="fw-medium"
                    class="mb-0"
                >
                    <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                        @input="getData"
                    ></b-form-select>
                </b-form-group>
                <div class="ml-auto mt-3 mt-md-0">
                    <b-pagination
                        @input="getData"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        class="my-0"
                    ></b-pagination>
                </div>
            </div>
            <!-- End Section -->
        </b-card>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';
export default {
    name: 'Customers',

    data: () => ({
        page: {
            title: 'SeachTable',
        },
        sortBy: '',
        sortDesc: false,
        filter: '',
        sortColumns: {
            id: 'Id',
            businessId: 'BusinessId',
            reasonTypeDescription: 'Name',
            firstName: 'FirstName',
            lastName: 'LastName',
            address2: 'Address2',
            createDate: 'CreateDate',
            origin: 'Origin',
        },
        fields: [
            {
                key: 'id',
                label: 'ID',
                sortable: true,
            },
            {
                key: 'businessId',
                label: 'Company ID',
                sortable: true,
            },
            {
                key: 'businessName',
                label: 'Company Name',
                sortable: true,
            },
            {
                key: 'address2',
                label: 'Address',
                sortable: true,
            },
            {
                key: 'createDate',
                label: 'Date Created',
                sortable: true,
            },
            {
                key: 'origin',
                label: 'Origin',
                sortable: true,
            },
            {
                key: 'isActive',
                label: 'Status',
                sortable: true,
            },
            {
                key: 'action',
                label: 'Action',
            },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],

        options: [
            { value: null, text: 'Please select an option' },
            { value: 'person', text: 'Person' },
        ],

        loading: false,
        debouncedSearch: null,
        companies: [],
    }),
    components: {},
    methods: {
        async getData() {
            const sortDirection = this.sortDesc ? 'desc' : 'asc';
            const sortProperty = this.sortColumns[this.sortBy] ?? '';

            this.loading = true;
            const response = await this.$store.dispatch('customer/get_allBusiness', {
                skip: this.currentPage,
                take: this.perPage,
                query: this.filter,
                sortDirection,
                sortProperty,
            });
            this.totalRows = response.dataRecords;
            this.companies = response.dataResult;
            this.loading = false;
        },
        onRowClicked(item) {
            this.$router.push(`/customers/companies/${item.id}`);
        },
        async changeStatus(data) {
            this.loading = true;
            await this.$store.dispatch('customer/update_customerStatus', {
                id: data.id,
                isActive: !data.isActive,
            });
            await this.getData();
            this.loading = false;
        },
    },
    computed: {
        ...mapGetters({}),
    },

    watch: {
        '$route.query.status'() {
            this.getData();
        },
        sortBy() {
            this.getData();
        },
        sortDesc() {
            this.getData();
        },
    },
    mounted() {},
    created() {
        this.debouncedSearch = debounce(this.getData, 300);
        this.getData();
    },
};
</script>
